<template>
  <LiveChatWidget
    ref="liveChatWidget"
    license="18756744"
    :sessionVariables="sessionVariables"
    :customerName="this.user.fullName"
  />
</template>

<script>
import { User } from '../logic/users/user/user'
import { LiveChatWidget } from '@livechat/widget-vue'

export default {
  name: 'App',
  components: {
    LiveChatWidget
  },
  data() {
    return {
      user: {}
    }
  },
  computed: {
    sessionVariables() {
      const userId = this.user.id ? String(this.user.id) : ''
      const baseUrl = 'https://abanprime.com/admin/user'
      const profileUrl = userId ? `${baseUrl}/${userId}/balances` : ''

      return {
        phone_number: String(this.user.phoneNumber || ''),
        email: String(this.user.email || ''),
        first_name: String(this.user.firstName || ''),
        last_name: String(this.user.lastName || ''),
        profile: profileUrl,
        source: window.location.origin,
      }
    }
  },
  created() {
    User.getMe().then((user) => {
      this.user = user
    }).catch(() => {})
  }
}
</script>
